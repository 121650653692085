import React from "react"
import { graphql } from "gatsby"
import {
  portableToPlainText,
  calculateTimeToRead,
  generateNameWithHonorific,
  generatePath,
  editionIsReleased,
} from "../lib/helpers"
import WebsiteMeta from "../components/WebsiteMeta"
import ListElement from "../components/ListElement"
import FadeIn from "../components/FadeIn"
import styled from "styled-components"

export default function sanityPersonTemplate({ data }) {
  const person = data.sanityPerson
  const { articles, editorInChief, editor, facultyAdvisor, contributor } = data

  // Purge un-released editions from each object
  const releasedArticles = articles.edges.filter(({ node }) => {
    return editionIsReleased(node.edition.release, node.edition.status)
  })
  const releasedEditorInChief = editorInChief.edges.filter(({ node }) => {
    return editionIsReleased(node.release, node.status)
  })
  const releasedEditor = editor.edges.filter(({ node }) => {
    return editionIsReleased(node.release, node.status)
  })
  const releasedFacultyAdvisor = facultyAdvisor.edges.filter(({ node }) => {
    return editionIsReleased(node.release, node.status)
  })
  const releasedContributor = contributor.edges.filter(({ node }) => {
    return editionIsReleased(node.release, node.status)
  })

  const name = generateNameWithHonorific(person.name, person.honorific)

  return (
    <>
      <WebsiteMeta title={name} description={`Check out work by ${name}`} />
      <Person>
        <FadeIn direction="up">
          <PageTitle>{name}</PageTitle>
        </FadeIn>
        <FadeIn direction="down" delay={150}>
          <Subheader>
            The following has been contributed to the Press.
          </Subheader>
        </FadeIn>

        {articles.edges.length +
          editorInChief.edges.length +
          editor.edges.length +
          facultyAdvisor.edges.length +
          contributor.edges.length ===
          0 && (
          <FadeIn direction="up" delay={250}>
            <NoRecords>Unfortunately no records were found.</NoRecords>
          </FadeIn>
        )}

        <FadeIn direction="up" delay={250}>
          {releasedArticles.length > 0 && (
            <Section>
              <SectionHeader>Articles:</SectionHeader>
              <List>
                {releasedArticles.map(({ node }, index) => (
                  <ListElement
                    link={generatePath(node.slug.current, "article")}
                    title={node.title}
                    line2={node.edition.name}
                    extras2={` — ${calculateTimeToRead(
                      portableToPlainText(node._rawContent)
                    )} min. read`}
                    index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    key={node._id}
                  />
                ))}
              </List>
            </Section>
          )}
        </FadeIn>

        <FadeIn direction="up" delay={250}>
          {releasedEditorInChief.length > 0 && (
            <Section>
              <SectionHeader>Editor-in-Chief:</SectionHeader>
              <List>
                {releasedEditorInChief.map(({ node }, index) => (
                  <ListElement
                    link={generatePath(node.slug.current, "article")}
                    title={node.name}
                    extras1="Released "
                    line2={node.formattedRelease}
                    index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    key={node._id}
                  />
                ))}
              </List>
            </Section>
          )}
        </FadeIn>

        <FadeIn direction="up" delay={250}>
          {releasedEditor.length > 0 && (
            <Section>
              <SectionHeader>Editor:</SectionHeader>
              <List>
                {releasedEditor.map(({ node }, index) => (
                  <ListElement
                    link={generatePath(node.slug.current, "article")}
                    title={node.name}
                    extras1="Released "
                    line2={node.formattedRelease}
                    index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    key={node._id}
                  />
                ))}
              </List>
            </Section>
          )}
        </FadeIn>

        <FadeIn direction="up" delay={250}>
          {releasedFacultyAdvisor.length > 0 && (
            <Section>
              <SectionHeader>Faculty Advisor:</SectionHeader>
              <List>
                {releasedFacultyAdvisor.map(({ node }, index) => (
                  <ListElement
                    link={generatePath(node.slug.current, "article")}
                    title={node.name}
                    extras1="Released "
                    line2={node.formattedRelease}
                    index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    key={node._id}
                  />
                ))}
              </List>
            </Section>
          )}
        </FadeIn>

        <FadeIn direction="up" delay={250}>
          {releasedContributor.length > 0 && (
            <Section>
              <SectionHeader>Contributor:</SectionHeader>
              <List>
                {releasedContributor.map(({ node }, index) => (
                  <ListElement
                    link={generatePath(node.slug.current, "article")}
                    title={node.name}
                    extras1="Released "
                    line2={node.formattedRelease}
                    index={index + 1 < 10 ? `0${index + 1}` : index + 1}
                    key={node._id}
                  />
                ))}
              </List>
            </Section>
          )}
        </FadeIn>
      </Person>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityPerson(_id: { eq: $id }) {
      honorific
      name
    }

    articles: allSanityArticle(
      filter: { author: { _id: { eq: $id } } }
      sort: { fields: [edition___release], order: DESC }
    ) {
      edges {
        node {
          _id
          title
          slug {
            current
          }
          edition {
            name
            release
            status
          }
          _rawContent(resolveReferences: { maxDepth: 1 })
        }
      }
    }

    editorInChief: allSanityEdition(
      filter: { editorsInChief: { elemMatch: { _id: { eq: $id } } } }
      sort: { fields: [release], order: DESC }
    ) {
      edges {
        node {
          _id
          name
          release
          formattedRelease: release(formatString: "MMMM D, YYYY")
          slug {
            current
          }
          status
        }
      }
    }

    editor: allSanityEdition(
      filter: { editors: { elemMatch: { _id: { eq: $id } } } }
      sort: { fields: [release], order: DESC }
    ) {
      edges {
        node {
          _id
          name
          release
          formattedRelease: release(formatString: "MMMM D, YYYY")
          slug {
            current
          }
          status
        }
      }
    }

    facultyAdvisor: allSanityEdition(
      filter: { facultyAdvisors: { elemMatch: { _id: { eq: $id } } } }
      sort: { fields: [release], order: DESC }
    ) {
      edges {
        node {
          _id
          name
          release
          formattedRelease: release(formatString: "MMMM D, YYYY")
          slug {
            current
          }
          status
        }
      }
    }

    contributor: allSanityEdition(
      filter: { specialThanks: { elemMatch: { _id: { eq: $id } } } }
      sort: { fields: [release], order: DESC }
    ) {
      edges {
        node {
          _id
          name
          release
          formattedRelease: release(formatString: "MMMM D, YYYY")
          slug {
            current
          }
          status
        }
      }
    }
  }
`

const Person = styled.div``

const PageTitle = styled.h1`
  margin: 0.5rem 0 0 0;
`

const Subheader = styled.p`
  margin: 0.25rem 0 1.25rem 0;
  color: var(--gray);
`

const NoRecords = styled.p`
  display: block;
  margin-top: 3rem;
  font-size: 1.15em;
`

const Section = styled.div`
  margin-top: 3rem;
`

const SectionHeader = styled.h2`
  margin-bottom: 1rem;
`

const List = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 1.5rem;
`
